import React, { memo, useState } from 'react';
import styled from 'styled-components';
import { TextInput, Icon } from '../General';
import StyleGuide from '../StyleGuide';

const View = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: stretch;
  background-color: ${StyleGuide.colors.backgrounds.input};
  border: 1px solid
    ${({ error, selected }) => {
      if (selected) {
        return StyleGuide.colors.fonts.buttonInverted;
      }
      if (error) {
        return StyleGuide.colors.fonts.alert;
      }
      return '';
    }};
  border-radius: 40px;
  padding: 0 20px;
  margin: 5px 0;
`;

const IconView = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  color: ${StyleGuide.colors.fonts.buttonInverted};
`;

const Input = styled(TextInput)`
  background-color: transparent;
  color: ${StyleGuide.colors.fonts.button};
  flex: 1;
  ::placeholder {
    color: ${StyleGuide.colors.fonts.button};
    opacity: 0.8;
    letter-spacing: 0.45em;
    font-size: 0.9em;
  }
  :focus {
    outline: none;
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${StyleGuide.colors.backgrounds.input} inset !important;
    -webkit-text-fill-color: ${StyleGuide.colors.fonts.white} !important;
  }
`;

const InputLine = ({ value, onChange, checkForMissingValue, icon, error, ...props }) => {
  const [selected, setSelected] = useState(false);
  return (
    <View error={error} selected={selected}>
      <IconView>
        <Icon name={icon} size={25} />
      </IconView>
      <Input
        value={value}
        onChange={({ target: { value: val } }) => onChange(val)}
        {...props}
        onFocus={() => setSelected(true)}
        onBlur={() => setSelected(false)}
      />
    </View>
  );
};

export default memo(InputLine);
