import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useRouter } from 'next/router';
import { animated, useSpring } from 'react-spring';
import styled, { keyframes } from 'styled-components';
import { padStart } from 'lodash';
import { differenceInSeconds, intervalToDuration, parseISO } from 'date-fns';
import { RingLoader, Dialog } from '../General';
import InputLine from './InputLine';
import StyleGuide from '../StyleGuide';
import { useTranslation } from '../i18n';
import { useInterval } from '../hooks';

const backgroundAnimation = keyframes`
  0% { 
    opacity:0;
    z-index:10;
    transform:translateX(100%);
    animation-timing-function: ease-in;
  }
  1% { 
    opacity:1;
    z-index:10;
    transform:translateX(0%);
  }
  20% {
    transform:translateX(0%);
    z-index:5;
    opacity:1;
  }
  21% { 
    transform:translateX(-70%);
    z-index:5;
    animation-timing-function: ease-out;
    opacity:1;
  }
22%{
  opacity:0;
}
 
`;

const BackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  color: transparent;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: none;
  opacity: 0;
  z-index: 5;
  animation: ${backgroundAnimation} 50s linear infinite 0s;
  animation-play-state: ${({ isStopped }) => (isStopped ? 'paused' : 'running')};
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  background: ${StyleGuide.colors.modalBackground};
`;
const Text = styled.span`
  color: ${StyleGuide.colors.fonts.white};
  font-size: 0.8em;
  letter-spacing: 0.3em;
  margin: 10px;
  text-align: center;
`;
const List = styled.ul`
  position: fixed;
  list-style-type: none;
  width: 100%;
  height: 100%;
  margin: 0px;
  top: 0px;
  left: 0px;
  z-index: 4;
  & li:nth-child(1) ${BackgroundImage} {
    background-image: url('/static/images/manhattan.png');
    animation-delay: -5s;
  }
  & li:nth-child(2) ${BackgroundImage} {
    background-image: url('/static/images/hongkong.png');
    animation-delay: 5s;
  }
  & li:nth-child(3) ${BackgroundImage} {
    background-image: url('/static/images/koeln.png');
    animation-delay: 15s;
  }
  & li:nth-child(4) ${BackgroundImage} {
    background-image: url('/static/images/sydney.png');
    animation-delay: 25s;
  }
  & li:nth-child(5) ${BackgroundImage} {
    background-image: url('/static/images/frankfurt.png');
    animation-delay: 35s;
  }
`;
const ModalLayer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 50;
  background-color: ${StyleGuide.colors.backgrounds.darkOverlay};
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10%;
`;

const ContentContainer = styled.div`
  background: ${StyleGuide.colors.backgrounds.login};
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 40px;
  min-width: 25%;
`;

const Button = styled.input`
  align-self: stretch;
  background: ${StyleGuide.colors.backgrounds.button};
  color: ${StyleGuide.colors.fonts.button};
  height: 50px;
  margin-top: 10px;
  border-radius: 25px;
  cursor: pointer;
  outline: inherit;
  border: none;
  text-transform: uppercase;
  font-weight: bold;
`;

const TextInput = styled(InputLine)`
  margin: 5px 10px;
`;

const Logo = styled.img`
  width: 339px;
  height: auto;
  margin: 15px 0px;
  align-self: center;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${StyleGuide.colors.backgrounds.darkTransparent};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const InputView = styled(animated.div)`
  display: flex;
  flex-direction: column;
`;

const TimerZone = styled.div`
  padding: 30px 10vw;
`;

const TimerText = styled.span`
  margin: 10px 0px;
  text-align: center;
  font-size: 1.2em;
  display: block;
`;

const TimeDuration = styled(TimerText)`
  font-weight: bold;
  font-size: 1.8em;
`;

const padZeros = (value) => padStart(value, 2, '0');

const formatDuration = (time) => {
  if (time.hours >= 1) {
    return `${padZeros(time.hours)}:${padZeros(time.minutes)}:${padZeros(time.seconds)}`;
  }
  return `${padZeros(time.minutes)}:${padZeros(time.seconds)}`;
};

const LoginComponent = ({ login, loading, error, token }) => {
  const { t } = useTranslation(['login', 'common']);
  const [shownError, setShownError] = useState(null);
  const [organization, setOrganization] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [animationToggle, setAnimationToggle] = useState(false);
  const [showLogoutInfo, setShowLogOutInfo] = useState(false);
  const [timeToLogin, setTimeToLogin] = useState(null);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (timeToLogin === null) {
      setTimer(null);
    } else {
      setTimer(formatDuration(intervalToDuration({ end: timeToLogin, start: new Date() })));
    }
  }, [timeToLogin]);

  useInterval(
    () => {
      const loginDate = timeToLogin;
      if (differenceInSeconds(loginDate, new Date()) <= 0) {
        setTimeToLogin(null);
        setTimer(null);
      } else {
        setTimer(formatDuration(intervalToDuration({ end: new Date(), start: loginDate })));
      }
    },
    timeToLogin ? 1000 : null
  );

  const {
    pathname,
    query: { loggedOut },
    push,
  } = useRouter();

  const cancelLoggedOut = useCallback(() => {
    push({ pathname }, undefined, { shallow: true });
  }, [push, pathname]);

  const [, setCookie, removeCookie] = useCookies(['Token']);
  useEffect(() => removeCookie('Token'), [removeCookie]);

  const router = useRouter();

  useEffect(() => {
    if (token) {
      setCookie('Token', token);
      router.push('/main');
    }
  }, [token, setCookie, router]);

  useEffect(() => {
    if (error) {
      console.log(error);
      if (error?.data?.statusCode === 21002) {
        setTimeToLogin(parseISO(error.data.items.nextLogin));
      } else if (error?.data?.statusCode === 21003) {
        setShownError('inactiveAccount');
      } else {
        setShownError('invalidEntries');
      }
    }
  }, [error]);

  useEffect(() => {
    if (shownError) {
      setAnimationToggle((state) => !state);
    }
  }, [shownError]);

  const spring = useSpring({
    from: { x: 0 },
    to: { x: animationToggle ? 3 : 0 },
    config: {
      friction: 20,
      mass: 1,
      tension: 2000,
      velocity: 50,
    },
  });

  const [isFocused, setIsFocused] = useState(true);

  useEffect(() => {
    function onFocus() {
      setIsFocused(true);
    }
    window.addEventListener('focus', onFocus);
    return () => window.removeEventListener('focus', onFocus);
  }, []);

  useEffect(() => {
    function onBlur() {
      setIsFocused(false);
    }
    window.addEventListener('blur', onBlur);
    return () => window.removeEventListener('blur', onBlur);
  }, []);

  return (
    <Fragment>
      <Background>
        <List>
          <li>
            <BackgroundImage isStopped={!isFocused}>Image1</BackgroundImage>
          </li>
          <li>
            <BackgroundImage isStopped={!isFocused}>Image2</BackgroundImage>
          </li>
          <li>
            <BackgroundImage isStopped={!isFocused}>Image3</BackgroundImage>
          </li>
          <li>
            <BackgroundImage isStopped={!isFocused}>Image4</BackgroundImage>
          </li>
          <li>
            <BackgroundImage isStopped={!isFocused}>Image5</BackgroundImage>
          </li>
        </List>
        <ModalLayer>
          {loading && (
            <LoadingOverlay>
              <RingLoader />
            </LoadingOverlay>
          )}

          <Container>
            <ContentContainer>
              <Logo src="/static/images/logo.png" />
              <Form
                onSubmit={(event) => {
                  event.preventDefault();
                  if (!username || !password || !organization) {
                    setShownError('missingParams');
                    return;
                  }
                  console.log('check');
                  setShownError('');
                  // Router.push('/dashboard');
                  login(organization, username, password);
                }}
              >
                <InputView
                  style={{
                    transform: spring.x
                      .interpolate({ range: [0, 1, 2, 3], output: [0, 10, -10, 0] })
                      .interpolate((x) => `translate3d(${x}px, 0,0)`),
                  }}
                >
                  <TextInput
                    icon="agency"
                    error={!!shownError}
                    value={organization}
                    placeholder={t('org')}
                    onChange={(val) => {
                      setOrganization(val.trim());
                    }}
                  />
                  <TextInput
                    icon="user"
                    error={!!shownError}
                    value={username}
                    onChange={(val) => {
                      setUsername(val.trim());
                    }}
                    placeholder={t('agent')}
                  />
                  <TextInput
                    icon="password"
                    error={!!shownError}
                    value={password}
                    onChange={(val) => {
                      setPassword(val.trim());
                    }}
                    placeholder={t('password')}
                    type="password"
                  />
                </InputView>

                {shownError && <Text>{t(shownError)}</Text>}
                <Button type="submit" value={t('login')} />
              </Form>
            </ContentContainer>
          </Container>
        </ModalLayer>
      </Background>
      {loggedOut && (
        <Dialog
          title={t('logout')}
          text={t('inactiveText')}
          buttons={[
            {
              text: t('common:understood'),
              action: () => {
                cancelLoggedOut();
              },
              isFocused: true,
            },
          ]}
        />
      )}
      {!!timeToLogin && (
        <Dialog
          title={t('loginFailed')}
          buttons={[
            { text: t('common:understood'), action: () => setTimeToLogin(null), isFocused: true },
          ]}
        >
          <TimerZone>
            <TimerText>{t('onlyIn1')}</TimerText>
            <TimeDuration>{timer}</TimeDuration>
            <TimerText>{t('onlyIn2')}</TimerText>
          </TimerZone>
        </Dialog>
      )}
    </Fragment>
  );
};

export default LoginComponent;
