import { connect } from 'react-redux';
import Login from './LoginComponent';
import { agentActions, agentSelectors } from '../agent';

const mapStateToProps = state => ({
  token: agentSelectors.getToken(state),
  loading: agentSelectors.getLoading(state),
  error: agentSelectors.getError(state),
});

const mapDispatchToProps = {
  login: (agency, username, password) => agentActions.login(agency, username, password),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
